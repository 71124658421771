<template>
  <div v-if="!isLoading">
    <DxDataGrid
        :data-source="dataSource"
        :show-borders="true"
        key-expr="storenumber"
        height="500px"
        :column-auto-width="true"
        :allow-column-reordering="true"
    >

      <DxSearchPanel
          :visible="true"
          :width="240"
          placeholder="Search..."
      />
      <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="false"
          :select-text-on-edit-start="true"
          start-edit-action="dblClick"
          :use-icons="true"
          mode="batch"
      />
      <DxPaging :enabled="false"/>

      <DxColumn data-field="storenumber" caption="Store #" :allow-editing="false"/>
      <DxColumn data-field="storename" caption="Store Name"/>
      <DxColumn data-field="groupname" caption="Group Name"/>
      <DxColumn data-field="streetaddress" caption="Address"/>
      <DxColumn data-field="city" caption="City"/>
      <DxColumn data-field="state" caption="State"/>
      <DxColumn data-field="zip" caption="Zip Code"/>

      <DxColumn data-field="corporateorfranshice" caption="Corporate Or Franchise"/>
      <DxColumn data-field="university" caption="University"/>
      <DxColumn data-field="fulladdress" caption="Full Address"/>
      <DxColumn data-field="latitude" caption="Latitude"/>
      <DxColumn data-field="longitude" caption="Longitude"/>
      <DxColumn data-field="dateopened" caption="Date Opened" data-type="date"/>
      <DxColumn data-field="dateclosed" caption="Date Closed" data-type="date"/>
      <DxColumn data-field="centerisland" caption="Center Island"/>
      
      <DxColumn data-field="possystem" caption="POS System"/>
      <DxColumn data-field="menuboards" caption="Menu Boards"/>
      <DxColumn data-field="dttimer" caption="DT Timer"/>
      <DxColumn data-field="timezone" caption="Time Zone"/>
      <DxColumn data-field="country_code" caption="Country Code"/>
      <DxColumn data-field="fad" caption="FAD"/>
      <DxColumn data-field="fmm" caption="FMM"/>
      <DxColumn data-field="par_level" caption="PAR Level"/>
      <DxColumn data-field="isincludedforreporting" caption="Included In Reporting"/>
      <DxColumn data-field="compnotincluded" caption="Not Comp"/>
      
      
      <DxScrolling
          column-rendering-mode="virtual"
          mode="infinite"/>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup, DxScrolling, DxSearchPanel
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {DxButton} from "devextreme-vue/button";

export default {
  name: "Locations",
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxCheckBox,
    DxSelectBox,
    DxScrolling,
    DxButton,
    DxSearchPanel
  },

  data() {
    return {
      dataSource: null,
      isLoading: false
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {

      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-utilities/locations`,
        updateUrl: `${url}/api/slims-utilities/locations/update`,
        key: 'storenumber',
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
      
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>
.data-grid {
  height: 250px
}
</style>