<template>
  <DxTabPanel>
    <DxItem
        title="Food"
        template="food-tab"
    />
    <template #food-tab>
      <products type="Food"></products>
    </template>
    <DxItem
        title="Beverage"
        template="beverage-tab"
    />
    <template #beverage-tab>
      <products type="Beverage"></products>
    </template>
    <DxItem
        title="Catering"
        template="catering-tab"
    />
    <template #catering-tab>
      <products type="Catering"></products>
    </template>
    <DxItem
        title="Unattributed"
        template="Unattributed-tab"
    />
    <template #Unattributed-tab>
      <products type="Null"></products>
    </template>
  </DxTabPanel>
</template>

<script>

import Products from "./Products";
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
export default {
  name: "MasterProducts",
  components: {
    Products, DxTabPanel, DxItem
  }
}
</script>

<style scoped>

</style>