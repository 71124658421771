<template>
  <DxTabPanel>
    <DxItem
        title="Published"
        template="published-tab"
    />
    <template #published-tab>
      <reports is-published="Published"></reports>
    </template>
    <DxItem
        title="Not Published"
        template="not-published-tab"
    />
    <template #not-published-tab>
      <reports is-published="Not Published"></reports>
    </template>
    
    <DxItem
        title="All Reports"
        template="all-reports-tab"
    />
    <template #all-reports-tab>
      <reports is-published="All"></reports>
    </template>
  </DxTabPanel>
</template>

<script>

import Reports from "./Reports";
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
export default {
  name: "MasterProducts",
  components: {
    Reports, DxTabPanel, DxItem
  }
}
</script>

<style scoped>

</style>