<template>
  <div v-if="!isLoading">
    <DxDataGrid
        :data-source="dataSource"
        :show-borders="true"
        key-expr="id"
        height="500px"
        :column-auto-width="true"
        :allow-column-reordering="true"
    >

      <DxSearchPanel
          :visible="true"
          :width="240"
          placeholder="Search..."
      />
      
      <DxPaging :enabled="false"/>

      <DxColumn data-field="dateRan" caption="Date Ran"  cell-template="date-template"/>
      <DxColumn data-field="isCompleted" caption="Is Complete"/>

      <template #date-template="{ data }">
        <span>{{formatRunDate(data.value)}}</span>
      </template>
      
      <DxScrolling
          column-rendering-mode="virtual"
          mode="infinite"/>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup, DxScrolling, DxSearchPanel
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {DxButton} from "devextreme-vue/button";

export default {
  name: "Locations",
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxCheckBox,
    DxSelectBox,
    DxScrolling,
    DxButton,
    DxSearchPanel
  },

  data() {
    return {
      dataSource: null,
      isLoading: false
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {

      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-utilities/logs`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });

      this.isLoading = false;
    },
    
    formatRunDate(runDate) {
      let date = new Date(runDate);
      date.setHours(date.getHours() + 2);
      let dateStr =
          ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
          ("00" + date.getDate()).slice(-2) + "/" +
          date.getFullYear() + " " +
          ("00" + date.getHours()).slice(-2) + ":" +
          ("00" + date.getMinutes()).slice(-2) + ":" +
          ("00" + date.getSeconds()).slice(-2);
      
      console.log(dateStr);
      
      return dateStr;
    }
  },
}
</script>

<style scoped>
.data-grid {
  height: 250px
}
</style>