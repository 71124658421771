<template>
  <div v-if="!isLoading">
    <DxDataGrid
        class="data-grid2"
        :data-source="dataSource"
        :show-borders="true"
        key-expr="reportWidgetId"
        height="500px"
        :column-auto-width="true"
        :allow-column-reordering="true"
    >
      <DxSearchPanel
          :visible="true"
          :width="240"
          placeholder="Search..."
      />
      <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="false"
          :select-text-on-edit-start="true"
          start-edit-action="dblClick"
          :use-icons="true"
          mode="batch"
      />
      <DxPaging :enabled="false"/>
      
      <DxColumn data-field="reportName" caption="Report Name" :allow-editing="false"/>
      <DxColumn data-field="lastUpdated" v-if="isPublished === 'All' || isPublished === 'Published'" caption="Published Date" data-type="date" :allow-editing="false"/>
      <DxColumn v-if="isPublished === 'All' || isPublished === 'Published'" data-field="isActive" caption="Is Active" :allow-editing="getIfEditableActive(this.isPublished)"/>
      <DxColumn v-if="isPublished === 'All' || isPublished === 'Not Published'" data-field="isPublished" caption="Is Published" :allow-editing="getIfEditablePublish(this.isPublished)"/>

      <DxScrolling
          column-rendering-mode="virtual"
          mode="infinite"/>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup, DxScrolling, DxSearchPanel
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {DxButton} from "devextreme-vue/button";

export default {
  name: "Locations",
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxCheckBox,
    DxSelectBox,
    DxScrolling,
    DxButton,
    DxSearchPanel
  },

  data() {
    return {
      dataSource: null,
      isLoading: false
    };
  },
  
  props: {
    isPublished: String
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {

      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-utilities/reports/` + this.isPublished,
        updateUrl: `${url}/api/slims-utilities/reports/update`,
        key: 'reportWidgetId',
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
      this.isLoading = false;
    },

    getIfEditableActive(publishType) {
      if (publishType === 'Published')
        return true;
      else return false;
    },

    getIfEditablePublish(publishType) {
      if (publishType === 'Not Published')
        return true;
      else return false;
    }
  }
}
</script>

<style scoped>
.data-grid {
  height: 250px
}

.data-grid2 {
  padding-top: 8px
}
</style>