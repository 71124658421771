<template>
  <div>
    <div class="uk-container uk-container-expand uk-margin-small-bottom nav-bar-css">
      <ul class="uk-subnav uk-subnav-pill nav-bar-css nav-bar-text-css">
        <li @click="toggleLocationsPage" :class="{'uk-active': showLocationsPage}"><a href="#" class="nav-bar-text-css">Locations</a>
        </li>
        <li @click="toggleChannelPage" :class="{'uk-active': showChannelPage}"><a href="#" class="nav-bar-text-css">Channel</a>
        </li>
        <li @click="toggleProductsPage" :class="{'uk-active': showProductsPage}"><a href="#" class="nav-bar-text-css">Products</a>
        </li>
<!--        <li @click="toggleReportsPage" :class="{'uk-active': showReportsPage}"><a href="#" class="nav-bar-text-css">Reports</a>-->
<!--        </li>-->
        <li @click="toggleShowUsersPage" :class="{'uk-active': showUsersPage}"><a href="#" class="nav-bar-text-css">Franchise Users</a>
        </li>
        <li @click="toggleShowLogsPage" :class="{'uk-active': showLogsPage}"><a href="#" class="nav-bar-text-css">Logs</a>
        </li>
      </ul>
    </div>

    <div v-if="showLocationsPage" class="uk-width-1-1@xl uk-padding-small">
      <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
        <div class="uk-card-header">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-auto"><h4 class="uk-text-primary">Locations Manager</h4></div>
            <div class="uk-width-expand uk-text-right panel-icons">
            </div>
          </div>
        </div>
        <div class="uk-card-body">
          <div class="chart-container">
            <locations></locations>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showChannelPage" class="uk-width-1-1@xl uk-padding-small">
      <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
        <div class="uk-card-header">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-auto"><h4 class="uk-text-primary">Channel Manager</h4></div>
            <div class="uk-width-expand uk-text-right panel-icons">
            </div>
          </div>
        </div>
        <div class="uk-card-body">
          <div class="chart-container">
            <channels></channels>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showProductsPage" class="uk-width-1-1@xl uk-padding-small">
      <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
        <div class="uk-card-header">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-auto"><h4 class="uk-text-primary">Products Manager</h4></div>
            <div class="uk-width-expand uk-text-right panel-icons">
            </div>
          </div>
        </div>
        <div class="uk-card-body">
          <div class="chart-container">
            <master-products></master-products>
          </div>
        </div>
      </div>
    </div>


    <div v-if="showUsersPage" class="uk-width-1-1@xl uk-padding-small">
      <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
        <div class="uk-card-header">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-auto"><h4 class="uk-text-primary">Franchise Users Manager</h4></div>
            <div class="uk-width-expand uk-text-right panel-icons">
              <button class="uk-button uk-button-primary uk-text-middle comp-button">
                <span class="comp-button-text" @click="showAddUser">Add User</span>
              </button>
            </div>
          </div>
        </div>
        <div class="uk-card-body">
          <div class="chart-container">
            <franchise-users></franchise-users>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showReportsPage" class="uk-width-1-1@xl uk-padding-small">
      <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
        <div class="uk-card-header">
          <div class="uk-grid uk-grid-small panel-icons">
            <div class="uk-width-auto"><h4 class="uk-text-primary">Report Manager</h4></div>
            <div class="uk-width-expand uk-text-right panel-icons">
            </div>
          </div>
        </div>
        <div class="uk-card-body">
          <div class="chart-container">
            <master-reports></master-reports>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showLogsPage" class="uk-width-1-1@xl uk-padding-small">
      <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
        <div class="uk-card-header">
          <div class="uk-grid uk-grid-small panel-icons">
            <div class="uk-width-auto"><h4 class="uk-text-primary">Store Proc Logs</h4></div>
            <div class="uk-width-expand uk-text-right panel-icons">
            </div>
          </div>
        </div>
        <div class="uk-card-body">
          <div class="chart-container">
            <logs></logs>
          </div>
        </div>
      </div>
    </div>


    <DxPopup
        :visible="popupVisible"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        :width="600"
        :height="240"
        title="Add User"
        @hidden="hideAddUser"
    >

      <div class="dx-field">
        <div class="dx-field-label">User: </div>
        <div class="dx-field-value">
          <DxSelectBox
              :search-enabled="true"
              search-expr="userName"
              :data-source="userList"
              display-expr="userName"
              value-expr="userId"
              @valueChanged="changedUser"
          />
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Franchise Group: </div>
        <div class="dx-field-value">
          <DxSelectBox
              :search-enabled="true"
              :data-source="franchiseList"
              display-expr="groupName"
              value-expr="groupName"
              @valueChanged="changedGroup"
          />
        </div>
      </div>
      
      <div class="button-css">
        <button v-if="!isSaving" class="uk-button uk-button-primary button-exact" @click="saveUser">  <span class="fa fa-floppy-o"> </span> Save</button>
        <small-loader-component class="button-exact" v-if="isSaving"></small-loader-component>
      </div>
      
    </DxPopup>
    
  </div>
</template>

<script>
import Locations from "./Components/Locations";
import Channels from "./Components/Channels";
import MasterProducts from "./Components/MasterProducts";
import MasterReports from "./Components/MasterReports";
import FranchiseUsers from "./Components/FranchiseUsers";
import {DxPopup} from "devextreme-vue/popup";
import DxSelectBox from "devextreme-vue/select-box";
import {createStore} from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import notify from "devextreme/ui/notify";
import logs from "./Components/Logs";
import SmallLoaderComponent from "../Assets/js/Shared/SmallLoaderComponent.vue";
export default {
  name: 'App',
  components: {
    SmallLoaderComponent,
    MasterProducts,
    Locations,
    Channels,
    MasterReports,
    FranchiseUsers,
    DxPopup,
    logs,
    DxSelectBox,
  },
  data() {
    return {
      showLocationsPage: true,
      showChannelPage: false,
      showProductsPage: false,
      showUsersPage: false,
      showLogsPage: false,
      showOther: false,
      showReportsPage: false,
      popupVisible: false,
      franchiseList: null,
      userList: null,
      selectedUserId: '',
      selectedGroup: '',
      isSaving:false
    };
  },

  mounted() {
    this.franchiseListLoad();
    this.userListLoad();
  },

  created() {

  },

  beforeDestroy() {

  },

  methods: {
    toggleLocationsPage() {
      this.toggleEverythingOff();

      this.showLocationsPage = true;
    },

    toggleEverythingOff() {
      this.showLocationsPage = false;
      this.showChannelPage = false;
      this.showProductsPage = false;
      this.showReportsPage = false;
      this.showUsersPage = false;
      this.showLogsPage = false;
    },

    franchiseListLoad() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.franchiseList = createStore({
        loadUrl: `${url}/api/slims-utilities/franchise-list`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });

      this.userList = createStore({
        loadUrl: `${url}/api/slims-utilities/new-users`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
    },

    userListLoad() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.userList = createStore({
        loadUrl: `${url}/api/slims-utilities/new-users`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
    },

    toggleChannelPage() {
      this.toggleEverythingOff();

      this.showChannelPage = true;
    },

    toggleProductsPage() {
      this.toggleEverythingOff();

      this.showProductsPage = true;
    },

    toggleShowUsersPage() {
      this.toggleEverythingOff();

      this.showUsersPage = true;
    },

    toggleReportsPage() {
      this.toggleEverythingOff();

      this.showReportsPage = true;
    },

    toggleShowLogsPage() {
      this.toggleEverythingOff();
      
      this.showLogsPage = true;
    },

    showAddUser() {
      this.popupVisible = true;
    },

    hideAddUser() {
      this.popupVisible = false;
    },

    getToastConfigurationUserAdded () {
      return {
        message: "User Creation Was Successful.",
        type: "success",
        height: 50,
        position: {
          at: "bottom",
          my: "center",
          of: window,
          offset: { x: 0 , y: -80}
        }
      }
    },

    changedUser(user) {
      this.selectedUserId = user.value;
    },

    changedGroup(group) {
      this.selectedGroup = group.value;
    },  

    saveUser() {
      this.isSaving = true;
      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      let me = this;
      axios.get(`${url}/api/slims-utilities/create-new-user`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          userId: this.selectedUserId,
          group: this.selectedGroup,
          clientId: clientId
        }
        
      }).then(response => {
        if (response.data.wasSuccessful) {
          this.isSaving = false;
          notify(this.getToastConfigurationUserAdded());
        }
      }).catch(err => {
      
      }).finally(function () {
        
      });
    }

  }


}
</script>

<style lang="scss">
.comp-button {
  height: 30px;
  display: inline-flex !important;
  align-items: center;
}

.button-exact {
  margin-top: 12px;
}


.button-css {
  border-top: solid 1px #e4e4e4;;
  text-align: center;
}
</style>